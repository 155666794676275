import React from 'react';
import styles from "./Header.module.css"
import product_icon from "../../assets/product_icon.png";
import instagram from "../../assets/instagram.png";
import vkontakte from "../../assets/vkontakte.png";
const Header = () => {
    return (
        <div className={styles.header}>
            <div className={styles.headerInfo}>
                <div className={styles.navbar}>
                    <img className={styles.mainIcon} src={product_icon}/>
                    <ul className={styles.navUl}>
                        <li><a href="#block1">Наши работы</a></li>
                        <li><a href="#block2">Прайс</a></li>
                        <li><a href="#block3">Контакты</a></li>
                    </ul>
                    <div className={styles.imgContact}>
                        <a href={"https://www.instagram.com/remontobuvi34.rf/"} target={"_blank"}><img src={instagram}/></a>
                        <a href={"https://vk.com/remontobuvi34"} target={"_blank"}><img src={vkontakte}/></a>
                    </div>
                </div>


                <div className={styles.infoBlock}>
                    <h1>Ремонт обуви и кожаных изделий</h1>
                    <p>Ремонт и рестоврация любой сложности! Куртки, сумки, чемодани и прочие изделия.</p>
                    <p>Замена пряжек, кнопок, клёпок и прочей фурнитуры</p>
                    <p>Изготовление ключей</p>
                    <h2>Опыт работы более 15 лет</h2>
                </div>
            </div>
        </div>
    );
};

export default Header;