import React from 'react';
import styles from "../Pricelist/Price.module.css";

const PricePage3 = () => {
    return (
        <div>
            <div className={'3'}>
                <div className={styles.priceList}>
                    <div className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Замена молнии на куртках</h5>
                                <span>700-2500р.</span>
                            </li>
                            <li>
                                <h5>Покраска куртки</h5>
                                <span>2500-7000р.</span>
                            </li>
                            <li>
                                <h5>Замена ручек на сумках</h5>
                                <span>1000-2500р.</span>
                            </li>
                            <li>
                                <h5> Замена ручек на чемоданах</h5>
                                <span>800-1500р.</span>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Замена колёсиков на чемоданах</h5>
                                <span>500-1000р.</span>
                            </li>
                            <li>
                                <h5>Замена механизма на чемоданах</h5>
                                <span>1500-2500р.</span>
                            </li>
                            <li>
                                <h5>Замена молнии на сумках</h5>
                                <span>600-2000р.</span>
                            </li>
                            <li>
                                <h5>Покраска сумки</h5>
                                <span>1500-4500р.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricePage3;