import React from 'react';
import styles from "../Pricelist/Price.module.css";

const PricePage4 = () => {
    return (
        <div>
            <div className={'4'}>
                <div className={styles.priceList}>
                    <div className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Английские</h5>
                                <span>150-300р.</span>
                            </li>
                            <li>
                                <h5>Финские</h5>
                                <span>150-250р.</span>
                            </li>
                            <li>
                                <h5>Изготовление ключей брелок домофона</h5>
                                <span>350-800р.</span>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Автомобильные</h5>
                                <span>300-1500р.</span>
                            </li>
                            <li>
                                <h5>Флажковый</h5>
                                <span>350-500р.</span>
                            </li>
                            <li>
                                <h5>Вертикальные</h5>
                                <span>350-1000р.</span>
                            </li>
                            <li>
                                <h5>Реечные (ригельный)</h5>
                                <span>500-1500.</span>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricePage4;