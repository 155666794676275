import React from 'react';
import styles from "../Pricelist/Price.module.css";

const PricePage2 = () => {
    return (
        <div>
            <div className={'2'}>
                <div className={styles.priceList}>
                    <div className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Замена кнопок от</h5>
                                <span>100р.</span>
                            </li>
                            <li>
                                <h5>Замена клёпок от</h5>
                                <span>100р.</span>
                            </li>
                            <li>
                                <h5>Замена люверсов от</h5>
                                <span>100р.</span>
                            </li>
                            <li>
                                <h5>Замена пуговиц на джинсах</h5>
                                <span>150-300р.</span>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Замена карабинов</h5>
                                <span>200-500р.</span>
                            </li>
                            <li>
                                <h5>Замена пряжек на ремнях</h5>
                                <span>500-1000р.</span>
                            </li>
                            <li>
                                <h5>Замена замочков на сумках</h5>
                                <span>700-2000р.</span>
                            </li>
                            <li>
                                <h5>Замена замочков на барсетках</h5>
                                <span>700-2000р.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricePage2;