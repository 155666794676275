import React from 'react';
import styles from './Reviews.module.css'


const Reviews = () => {
    return (
        <div className={styles.reviews}>
            <div className={styles.reviewsBox}>
                <div className={styles.reviewPerson}>
                    <img src={require('../../assets/review1.jpg')}/>
                    <p>Здравствуйте! Отдавала короткие сапоги на перекраску в черный цвет. Спасибо большое! Качество исполнения на высоте! А за оперативность отдельное спасибо.</p>
                    <h5>Софья Рудницкая</h5>
                </div>
                <div className={styles.reviewPerson}>
                    <img src={require('../../assets/review2.jpg')}/>
                    <p>Здравствуйте ещё раз) Хотела написать большое спасибо за проделанную работу с курткой! Муж в восторге!)) Это был сюрприз) Стала лучше чем была Вы настояший профессионал! СПАСИБО!)</p>
                    <h5>Катя Неминущая</h5>
                </div>
                <div className={styles.reviewPerson}>
                    <img src={require('../../assets/review3.jpg')}/>
                    <p>Ещё раз благодарю за проделанную работу!!! Мои любимые кроссовки получили вторую жизнь, смело могу Вас рекомендовать!</p>
                    <h5>Алла Сергеевна</h5>
                </div>
            </div>
        </div>
    );
};

export default Reviews;