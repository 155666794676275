import React from 'react';
import styles from './Footer.module.css'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';


const FooterPage = () => {
    const defaultState = {
        center: [48.752276, 44.482538],
        zoom: 18,
    };

    return (
        <div className={styles.footer} id="block3">
            <div className={styles.footerTextBox}>
                <div>
                    <h1>Наши контакты</h1>
                    <h3>Не откладывайте - свяжитесь с нами прямо сейчас!</h3>
                    <div></div>
                </div>
                <div className={styles.footerContactBlock}>
                    <div className={styles.contactBox}>
                        <a href={'https://wa.me/79616584497'} target={"_blank"}><img src={require('../../assets/whatsapp.png')}/></a>
                        <a href={"tg://resolve?domain=Hrachkarapetyan"} target={"_blank"}><img src={require('../../assets/telegram.png')}/></a>
                        <a href="tel:+78142332211" className={styles.telephone}>+7(961)-658-44-97</a>
                        <p>- прямая связь с нами</p>
                    </div>
                    <div className={styles.contactBox}>
                        <img src={require('../../assets/iconClock.png')}/>
                        <p>ПH-ПT с 9:00-19:00, СБ с 10:00-15:00</p>
                    </div>
                    <div className={styles.contactBox}>
                        <img src={require('../../assets/mapIcon.png')}/>
                        <p>проспект Маршала Жукова, 169</p>
                    </div>
                </div>
            </div>
            <div className={styles.map}>
                <YMaps>
                    <Map defaultState={defaultState} width={'100%'} height={'100%'}>
                        <Placemark geometry={[48.752276, 44.482538]} />
                    </Map>
                </YMaps>
            </div>
        </div>
    );
};

export default FooterPage;