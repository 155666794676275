import React from 'react';
import Header from "./components/Header/Header";
import Works from "./components/BeforeAfter/Works";
import Price from "./components/Pricelist/Price";
import Reviews from "./components/reviews/Reviews";
import FooterPage from "./components/footerPage/FooterPage";

function App() {
  return (
    <div className="App">
        <Header/>
        <Works/>
        <Price/>
        <Reviews/>

        <FooterPage/>
    </div>
  );
}

export default App;
