import React, {useState} from 'react';
import styles from './Price.module.css'
import PricePage1 from "../PricePages/PricePage_1";
import PricePage2 from "../PricePages/PricePage_2";
import PricePage3 from "../PricePages/PricePage_3";
import PricePage4 from "../PricePages/PricePage_4";


const shoes = [
    {
        id: "1",
        number: "1",
        model: "Обувь"
    },
    {
        id: "2",
        number: "2",
        model: "Фурнитура"
    },
    {
        id: "3",
        number: "3",
        model: "Кожгалантерея"
    },
    {
        id: "4",
        number: "4",
        model: "Изготовление ключей"
    },
]


const Price = () => {
    const [price, setPrice] = useState('1');
    const [active, setActive] = useState('1')

    const handleItemClick = (tabName:string) => {
        setPrice(tabName);
        setActive(tabName)
    };

    const renderContent = () => {
        switch (price) {
            case '1':
                return <PricePage1/>
                break;
            case '2':
                return <PricePage2/>
                break;
            case '3':
                return <PricePage3/>
                break;
            case '4':
                return <PricePage4/>
                break;
        }
    }


    return (
        <div className={styles.pricePage} id="block2">
            <div className={styles.priceTextBox}>
                <h1 className={styles.priceText}>Прайс-лист нашей мастерской</h1>
                <div className={styles.line}></div>
            </div>

            <div>
                <div className={styles.priceNamesBox}>
                    <ul className={styles.priceNameTag}>
                        {shoes.map((item:{id:string, number:string, model:string}) => {
                            return (
                                <li key={item.id} onClick={()=>handleItemClick(item.number)} className={active === (item.number) ? styles.activeItem : ''}>{item.model}</li>
                            )
                        })}
                    </ul>
                </div>
                <div className={styles.pages}>{renderContent()}</div>
            </div>
        </div>
    );
};

export default Price;
