import React from 'react';
import styles from "../Pricelist/Price.module.css";

const PricePage1 = () => {
    return (
        <div>
            <div className={'1'}>
                <div className={styles.priceList}>
                    <div className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Замена набоек женских</h5>
                                <span>400-1000р.</span>
                            </li>
                            <li>
                                <h5>Замена набоек мужские</h5>
                                <span>600-1500р.</span>
                            </li>
                            <li>
                                <h5>Профилактика</h5>
                                <span>1000-1800р.</span>
                            </li>
                            <li>
                                <h5>Замена молнии на сапогах</h5>
                                <span>450-1200р.</span>
                            </li>
                            <li>
                                <h5>Замена бегунков</h5>
                                <span>200-400р.</span>
                            </li>
                            <li>
                                <h5>Замена супинатора</h5>
                                <span>500-1000р.</span>
                            </li>
                            <li>
                                <h5>Скрытая латка</h5>
                                <span>300-700р.</span>
                            </li>
                        </ul>
                    </div>
                    <div  className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Подклейка</h5>
                                <span>200-600 руб.</span>
                            </li>
                            <li>
                                <h5>Прошивка в круговую </h5>
                                <span>700-1000 руб.</span>
                            </li>
                            <li>
                                <h5>Покраска обуви</h5>
                                <span>1500-5000 руб.</span>
                            </li>
                            <li>
                                <h5>Замена подошвы</h5>
                                <span>2000-3500 руб.</span>
                            </li>
                            <li>
                                <h5>Ушивка голенища</h5>
                                <span>1500-2500 руб.</span>
                            </li>
                            <li>
                                <h5>Растяжка голенища</h5>
                                <span>1000-1500 руб.</span>
                            </li>
                            <li>
                                <h5>Растяжка в носовой части</h5>
                                <span>500-800 руб.</span>
                            </li>
                        </ul>
                    </div>
                    <div  className={styles.priceListBox}>
                        <ul>
                            <li>
                                <h5>Замена каблуков муж.</h5>
                                <span>1500-2000 руб.</span>
                            </li>
                            <li>
                                <h5>Замена каблуков жен.</h5>
                                <span>1500-2500 руб.</span>
                            </li>
                            <li>
                                <h5>Замена пряжек на обуви</h5>
                                <span>200-400 руб.</span>
                            </li>
                            <li>
                                <h5>Замена липучек</h5>
                                <span>200-500 руб.</span>
                            </li>
                            <li>
                                <h5>Вставка резинок</h5>
                                <span>500-1500 руб.</span>
                            </li>
                            <li>
                                <h5>Замена союзки на кроссовках</h5>
                                <span>1500-2500 руб.</span>
                            </li>
                            <li>
                                <h5>Замена кож. задников</h5>
                                <span>1000-1500 руб.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricePage1;