import React from 'react';
import styles from "./Works.module.css"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import firstdo from '../../assets/bagdo.jpg'
import firstposle from '../../assets/bagposle.jpg'
import bootdo from '../../assets/bootdo.jpg'
import bootposle from '../../assets/bootposle.jpg'
import shoesdo from '../../assets/shoesdo.jpg'
import shoesposle from '../../assets/shoesposle.jpg'



const Works = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false
    };

    // @ts-ignore
    const CustomSlide = ({ children }) => (
        <div className={styles.children}>
            {children}
        </div>
    );

    return (
        <div className={styles.works} id="block1">
            <div className={styles.worksTextBox}>
                <h1 className={styles.h1}>Восстановим красоту ваших изделий!</h1>
                <div className={styles.line}></div>
            </div>


            <Slider {...settings}>
                <CustomSlide>
                        <img src={firstdo} className={styles.slideImg}/>
                        <img src={firstposle} className={styles.slideImg}/>
                </CustomSlide>
                <CustomSlide>
                    <img src={bootdo} className={styles.slideImg}/>
                    <img src={bootposle} className={styles.slideImg}/>
                </CustomSlide>
                <CustomSlide>
                    <img src={shoesdo} className={styles.slideImg}/>
                    <img src={shoesposle} className={styles.slideImg}/>
                </CustomSlide>
                <CustomSlide>
                    <img src={require('../../assets/lockerdo.jpg')} className={styles.slideImg}/>
                    <img src={require('../../assets/lockerposle.jpg')} className={styles.slideImg}/>
                </CustomSlide>
                <CustomSlide>
                    <img src={require('../../assets/4do.jpg')} className={styles.slideImg}/>
                    <img src={require('../../assets/4posle.jpg')} className={styles.slideImg}/>
                </CustomSlide>
            </Slider>
        </div>
    );
};

export default Works;